@import "~@iqmetrix/antd/dist/accessibility.css";
@import '~@iqmetrix/style-tokens/dist/scss/tokens-accessibility';

.ant-carousel{
  .slick-dots li {
    background-color: $color-grey-400;

    &.slick-active button {
      background-color: $color-black;
    }
  }

  .slick-dots {
    position: static;
    margin-bottom: 0px;
    margin-top: $spacing-base-large;
  }

  img {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
  }
}

.ant-card.manage-card{
    background: $color-grey-50;
}


.textarea-block{
  .ant-input-textarea-show-count::after{
    color: $color-grey-600;
  }
 
  position: relative;
  .label {
    color: $color-grey-600;
    bottom: -20px;
    position: absolute;
    left: 5px;
  }
}

.slick-current>div{
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex{
  display: flex;
}

.justify-center{
  justify-content: center;
}

.ant-skeleton-element .ant-skeleton-image{
  width: 200px;
  height: 200px;
  border-radius: 10px;
}

.ant-skeleton-element .ant-skeleton-image-svg{
  height: 100px;
  width: 100px;
}